<template>
    <CommandEditorComponent :command-id="$route.params.id" />
</template>

<script>

import CommandEditorComponent from "@/components/Command/CommandEditorComponent";

export default {
    name: "EventEditView",
    components: {CommandEditorComponent},
}
</script>

<style scoped>

</style>
