<template>
    <div>
        <button class="btn btn-success m-1" @click="$parent.$emit('play')">
            <i class="fas fa-play"></i>
        </button>
        <button class="btn btn-primary m-1" @click="$parent.$emit('moveUp')">
            <i class="fas fa-arrow-up"></i>
        </button>
        <button class="btn btn-primary m-1" @click="$parent.$emit('moveDown')">
            <i class="fas fa-arrow-down"></i>
        </button>
        <button class="btn btn-primary m-1" @click="$parent.$emit('duplicate')">
            <i class="fas fa-copy"></i>
        </button>
        <button class="btn btn-danger m-1" @click="$parent.$emit('delete')">
            <i class="fas fa-trash-alt"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: "CommandActionsComponent"
}
</script>

<style scoped>

</style>
