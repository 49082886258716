<template>
    <!-- Page Wrapper -->
    <div id="wrapper" :key="draw">
        <!-- Sidebar -->
        <SidebarComponent />
        <!-- End of Sidebar -->

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">

                <!-- Topbar -->
                <TopbarComponent />
                <!-- End of Topbar -->

                <!-- Begin Page Content -->
                <router-view class="container-fluid position-relative pt-4" ></router-view>
                <!-- /.container-fluid -->

            </div>
            <!-- End of Main Content -->

            <!-- Footer -->
            <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Nerdotron 2022</span>
                    </div>
                </div>
            </footer>
            <!-- End of Footer -->

        </div>
        <!-- End of Content Wrapper -->

    </div>
    <!-- End of Page Wrapper -->

    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded">
        <i class="fas fa-angle-up"></i>
    </a>

</template>

<script>
import SidebarComponent from "@/components/Section/SidebarComponent";
import TopbarComponent from "@/components/Section/TopbarComponent";
import {mapState} from "vuex";
import axios from "axios";
export default {
    name: 'App',
    components: {
        TopbarComponent,
        SidebarComponent,
    },
    data() {
        return {
            draw: 0
        }
    },
    computed: {
        ...mapState({
            'actor': state => state.context.actor,
        }),
    },
    watch: {
        actor() {
            if(this.actor) {
                axios.defaults.headers.common['acting_id'] = this.actor.id;
            } else {
                if(typeof axios.defaults.headers.common['acting_id'] !== 'undefined'){
                    delete axios.defaults.headers.common['acting_id'];
                }
            }
            this.draw++;
        }
    },
    mounted() {
        if (document.getElementById('responsiveVoice')) return; // was already loaded
        var scriptTag = document.createElement("script");
        scriptTag.src = process.env.VUE_APP_BACKEND_URL+'/js/responsivevoice.js';
        scriptTag.id = "responsiveVoice";
        document.getElementsByTagName('head')[0].appendChild(scriptTag);

        if(this.actor) {
            axios.defaults.headers.common['acting_id'] = this.actor.id;
        } else {
            if(typeof axios.defaults.headers.common['acting_id'] !== 'undefined'){
                delete axios.defaults.headers.common['acting_id'];
            }
        }
    }
}
</script>

<style>

</style>
