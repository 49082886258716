<template>
    <tr v-if="!deleted">
        <td>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Name" v-model="variableData.name">
            </div>
        </td>
        <td>
            <div class="input-group">
                <input :type="type" class="form-control" placeholder="Value" v-model="variableData.value">
            </div>
        </td>
        <td class="text-right" style="white-space: nowrap;">
            <button class="btn btn-primary m-1" @click="show"><i class="fa fa-eye"/></button>
            <button class="btn btn-primary m-1" @click="save"><i class="fa fa-save"/></button>
            <button :disabled="variableData.id == null" :class="{disabled: variableData.id == null}" class="btn btn-danger m-1" @click="remove"><i class="fa fa-trash"/></button>
        </td>
    </tr>

</template>

<script>

import axios from "axios";
import Variable from "@/model/variable";

export default {
    name: "VariableListItemComponent",
    components: {},
    props: ['variable'],
    data: ()=>{
        return {
            variableData: {},
            type: 'password',
            deleted: false,
        }
    },
    computed:{

    },
    methods: {
        show() {
            this.type = this.type === 'password' ? 'text' : 'password';
        },
        remove(){
            axios.delete(process.env.VUE_APP_BACKEND_URL+'/api/v1/variables/'+this.variable.id).then(()=>{
                this.deleted = true;
            })
        },
        save(){
            this.variableData.save().then(()=>{
                this.$emit('refresh');
            });

        }
    },
    mounted() {
        this.variableData = new Variable(this.variable);
    }
}
</script>
