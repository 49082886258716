import {forEach} from "lodash-es";
import axios from "axios";

class Command{
    id = null;
    commands = [];
    name = [];
    configuration = {};
    script = "";
    post_script = "";

    constructor(attributes = []) {
        this._fill(attributes);
    }

    _fill(attributes = []) {
        forEach(attributes, (value, attribute)=> {
            this[attribute] = value;
        });
        return this;
    }

    toArray() {
        return {
            id: this.id,
            commands: this.commands,
            name: this.name,
            script: this.script,
            post_script: this.post_script,
            configuration: this.configuration,
        }
    }

    save() {
        if(this.id === null){
            return this._create();
        } else {
            return this._update();
        }
    }

    async _create() {
        let response = await axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/services', this.toArray());
        return this._fill(response.data.data);
    }

    async _update() {
        let response = await axios.put(process.env.VUE_APP_BACKEND_URL+'/api/v1/services/'+this.id, this.toArray());
        return this._fill(response.data.data);
    }
}

export default Command;
