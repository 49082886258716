import CommandBase from "@/command/CommandBase";

class WebRequestCommand extends CommandBase{

    name = 'Web Request';
    type = 'WebRequest';
    hasNext = false;

    configuration = {
        method: "GET",
        url: "",
        body: "",
    }

}

export default WebRequestCommand;
