<template>
    <tr v-if="!deleted">
        <th scope="row">
            {{command.name}}
        </th>
        <td>{{commandTrigger}}</td>
        <td class="text-right">
            <button class="btn btn-success m-1" @click="play">
                <i class="fas fa-play"></i>
            </button>
            <button class="btn btn-primary m-1" @click="edit">
                <i class="fas fa-pen"></i>
            </button>
            <button class="btn btn-danger m-1" @click="remove">
                <i class="fas fa-trash-alt"></i>
            </button>
        </td>
        <td>
            <input type="checkbox" :checked="command.enabled_at != null" />
        </td>
    </tr>
</template>

<script>

import Events from "@/model/events";
import {get} from "lodash-es";
import axios from "axios";

export default {
    name: "CommandListItemComponent",
    components: {},
    props: ['command'],
    data(){
        return {
            deleted: false
        }
    },
    computed:{
        commandTrigger() {
            return get(Events.events, this.command.triggers, this.command.triggers);
        }
    },
    methods: {
        remove(){
            this.deleted = true;
            axios.delete(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/'+this.command.id);
        },
        play(){
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/live', {
                commands: this.command.commands
            });
        },
        edit(){
            this.$router.push({path: '/commands/'+this.command.id});
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
    .chat-message:hover{
        background-color: #9147ff!important;
        color:white;
    }
</style>
