<template>
    <ChatBoxComponent />
</template>

<script>
import ChatBoxComponent from "@/components/Chat/ChatBoxComponent";
export default {
    name: "Home-View",
    components: {ChatBoxComponent}
}
</script>

<style scoped>

</style>
