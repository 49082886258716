<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-project-diagram align-baseline mr-2"/>
                            <input class="form-control" placeholder="Action Title" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>
            </div>

            <div class="collapse" :class="{show:!collapsed}">
                <div class="bg-gray-400 container-fluid p-3">
                    <div class="row">
                        <div class="col-md-12">
                            <span class="m-1">
                                <button class="btn btn-primary" @click="addClause">Add Clause</button>
                            </span>
                            <span class="m-1">
                                <select class="btn btn-dark" v-model="commandData.configuration.operator">
                                    <option value="AND">AND</option>
                                    <option value="OR">OR</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-2" v-for="(condition,index) in commandData.configuration.conditions" :key="index">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" class="form-control" @input="e => {updateCondition(index, 'firstValue', e.target.value)}" :value="condition.firstValue" placeholder="First value">
                                <select class="form-control" :value="condition.operator" @change="e => {updateCondition(index, 'operator', e.target.value)}">
                                    <option value="=">=</option>
                                    <option value="!=">!=</option>
                                    <option value="<">{{"<"}}</option>
                                    <option value="<=">{{ "<=" }}</option>
                                    <option value=">=">{{ ">=" }}</option>
                                    <option value=">">{{ ">" }}</option>
                                    <option value="contains">contains</option>
                                    <option value="ncontains">don't contains</option>
                                </select>
                                <input type="text" class="form-control" @input="e => {updateCondition(index, 'secondValue', e.target.value)}" :value="condition.secondValue" placeholder="Second value">
                                <a class="btn text-gray-600" v-if="hasMultipleConditions" @click="delClause(index)"><i class="fa fa-trash" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <CommandActionSelector :value="selectedNextCommand" @input="e => {selectedNextCommand = e.target.value}" class="btn-dark"/>
                            <button class="btn btn-primary ml-2" @click="addAction"><i class="fas fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <CommandViewerComponent v-model="commandData.configuration.next" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, merge} from "lodash-es";
import CommandActionSelector from "@/components/Command/CommandActionSelector";
import {defineAsyncComponent} from "vue";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import ConditionalCommand from "@/command/ConditionalCommand";

export default {
    name: "ConditionalComponent",
    components: {
        CommandActionsComponent,
        CommandActionSelector,
        "CommandViewerComponent": defineAsyncComponent(() => import('@/components/Command/CommandViewerComponent.vue'))
    },
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null,
            selectedNextCommand: null,
        }
    },
    methods: {
        addAction(){
            if(this.selectedNextCommand){
                this.commandData.configuration.next.commands.push(new this.selectedNextCommand.component);
            }
        },
        updateCondition(index, key, value) {
            this.commandData.configuration.conditions[index][key] = value;
        },
        addClause(){
            this.commandData.configuration.conditions.push({'firstValue':'','secondValue':'','operator':'='})
        },
        delClause(index){
            this.commandData.configuration.conditions.splice(index, 1);
        }
    },
    computed: {
        hasMultipleConditions() {
            return this.commandData.configuration.conditions.length > 1;
        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        }
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new ConditionalCommand)[key] === 'object') {
                this.commandData[key] = merge((new ConditionalCommand)[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
    },
}
</script>

<style scoped>

</style>
