import {uniq} from "lodash-es";

class CommandBase{
    name = null;
    type = null;
    hasNext = false;
    configuration = {};
    next = {
        commands: []
    }
    identifier = null;
    is_visible = true;

    constructor() {
        if(this.name) {
            this.name = this.constructor.name;
        }
        if(!this.type) {
            this.type = this.constructor.type;
        }
        if(!this.identifier) {
            this.identifier = uniq();
        }
    }

}

export default CommandBase;
