<template>
    <div>
        <h1>Access sharing</h1>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <span class="nav-link" @click="activeTab = 'my access'" :class="{active: activeTab === 'my access'}">My access</span>
            </li>
            <li class="nav-item">
                <span class="nav-link" @click="activeTab = 'shared access'" :class="{active: activeTab === 'shared access'}">Shared access</span>
            </li>
        </ul>
        <MyAccessComponent :shares="myAccesses" v-if="activeTab === 'my access'" />
        <MySharedAccessComponent @refresh="loadShares" :shares="myShares" v-if="activeTab === 'shared access'" />
    </div>
</template>
<script>
import MyAccessComponent from "@/components/viewSpecific/AccessShare/MyAccessComponent";
import MySharedAccessComponent from "@/components/viewSpecific/AccessShare/MySharedAccessComponent";
import axios from "axios";
import jwt from "@/utils/jwt";
export default {
    name:'AccessShareView',
    components: {MySharedAccessComponent, MyAccessComponent},
    data: () => {
        return {
            activeTab: 'my access',
            sharing: [],
            loading: true,
        }
    },
    computed: {
        myShares() {
            return this.sharing.filter(((share) => {
                return share.from_user_id == jwt.authUser().id;
            }));
        },
        myAccesses() {
            return this.sharing.filter(((share) => {
                return share.to_user_id == jwt.authUser().id;
            }));
        }
    },
    methods: {
        loadShares() {
            this.loading = true;
            axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/sharing').then((response)=>{
                this.sharing = response.data;
                this.loading = false;
            })
        }
    },
    mounted() {
        this.loadShares()
    }
}
</script>

<style scoped>

</style>
