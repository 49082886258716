class Events {
    static events = {
        'ChannelRaid': 'Raid',
        'ChannelFollow': 'New Follow',
        'ChannelChannelPointsCustomRewardRedemptionAdd': 'Channel points redeemed',
        'ViewerEntrance': 'Viewer entrance',
        'PcgSpawn': 'PokemonCommunityGame spawn',
    };
}

export default Events;
