import CommandBase from "@/command/CommandBase";

class ConditionalCommand extends CommandBase{

    name = 'Conditional';
    type = 'Conditional';
    hasNext = true;

    configuration = {
        operator: "AND",
        caseSensitive: false,
        conditions: [
            {
                firstValue: "",
                secondValue: "",
                operator: "="
            }
        ],
        next: {
            commands:[]
        }
    }

}

export default ConditionalCommand;
