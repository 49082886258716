import { createApp } from "vue";
import {createRouter, createWebHistory} from "vue-router";
import routes from "@/routes/routes";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/admin.min.css';
import '@/assets/css/fa.min.css';
import '@/assets/css/index.css';
import jwt from "@/utils/jwt";
import App from "@/App";

import axios from "axios";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import cookie from "cookiejs";
import store from "@/store";
// 1. Define route components.
// These can be imported from other files

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.


// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
});

// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router);

router.beforeEach((to) => {
    if(to.name === 'Login'){
        return true;
    }

    if(jwt.hasValidToken()) {
        return true;
    }

    return { name: 'Login' }
})

axios.defaults.headers.common['Authorization'] = 'Bearer '+cookie.get('jwt');

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    wsPort: parseInt(process.env.VUE_APP_WS_PORT),
    key: 'NERD',
    wsHost: process.env.VUE_APP_WS_URL,
    forceTLS: false,
    authEndpoint: process.env.VUE_APP_BACKEND_URL+'/broadcasting/auth',
    auth: {
        headers: {
            authorization: 'Bearer '+cookie.get('jwt')
        }
    }
});
app.use(store);
app.mount('#app')
