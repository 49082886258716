// initial state
import { has} from "lodash-es";

const state = () => ({
    channels: [],
    channelHistories: {}
})

// getters
const getters = {
}

// actions
const actions = {
    listenToChannel ({ state, commit }, channel) {
        if(!state.channels.includes(channel)){
            commit('addChannelToListener', channel);
            window.Echo.private(`channel.chat.${channel}`)
                .listen('.ChatMessageReceived', (message) => {
                    commit('addMessageToHistory', message);
                });
        }

    }
}

// mutations
const mutations = {
    addMessageToHistory (state, message) {
        state.channelHistories[message.channel].push(message);
    },
    addChannelToListener (state, channel) {
        state.channels.push(channel);
        if(!has(state.channelHistories, channel)) {
            state.channelHistories[channel] = [];
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
