<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-link align-baseline mr-2"/>
                            <input class="form-control" placeholder="Action Title" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>
            </div>

            <div class="collapse" :class="{show:!collapsed}">
                <div class="card-body">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Action
                            </span>
                        </div>
                        <select class="form-control" v-model="commandData.configuration.action">
                            <option value="ban">Ban</option>
                        </select>
                    </div>
                    <div class="input-group mt-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Target Name
                            </span>
                        </div>
                        <input class="form-control" v-model="commandData.configuration.targetName" />
                    </div>
                    <div class="input-group mt-2" v-if="hasReason">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Reason
                            </span>
                        </div>
                        <input class="form-control" v-model="commandData.configuration.reason" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, merge} from "lodash-es";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import ModerationCommand from "@/command/ModerationCommand";

export default {
    name: "WebRequestComponent",
    components: {CommandActionsComponent},
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    computed: {
        hasReason() {
            return ['ban'].includes(this.commandData.configuration.action);
        },
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null
        }
    },
    methods: {
        jsonBodyChange(e) {
            var obj = JSON.parse(e.target.value);
            this.commandData.configuration.body = JSON.stringify(obj, undefined, 4);
        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        }
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new ModerationCommand)[key] === 'object') {
                this.commandData[key] = merge((new ModerationCommand())[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
    },
}
</script>

<style scoped>

</style>
