<template>
    <component :is="layout"></component>
</template>

<script>
import AppLayout from "@/Layout/AppLayout";
import {get} from "lodash-es";
import { mapActions } from 'vuex'

export default {
    name: "App",
    computed: {
        layout(){
            return get(this.$route, 'meta.layout', AppLayout);
        }
    },
    methods: {
        ...mapActions({
            'setDefaultContext': 'context/setDefaultContext' // map `this.add()` to `this.$store.dispatch('increment')`
        })
    },
    created() {
        this.setDefaultContext();
    }
}
</script>

<style scoped>

</style>
