<template>
    This is about
</template>

<script>
export default {
    name:'About-View'
}
</script>

<style scoped>

</style>
