<template>
    <nav class="navbar navbar-expand navbar-dark bg-twitch-light topbar static-top shadow" :class="{'bg-gradient-info': actor}">
        <div class="rounded-pill bg-dark text-white px-3 py-2" v-if="actor">You are using access for {{actor.username}} | <span class="text-white btn btn-sm" @click="resetActor"><u>Get out</u></span> </div>
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" href="https://wiki.nerdotron.app/">
                    <i class="fas fa-book fa-fw mr-2 text-gray-400"></i> Wiki
                </a>
            </li>
            <div class="topbar-divider d-none d-sm-block"></div>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="mr-2 d-none d-lg-inline text-white small">{{ displayName }}</span>
                    <img class="img-profile rounded-circle"
                         :src="profileUrl">
                </a>
                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                     aria-labelledby="userDropdown">
                    <router-link to="/settings" class="dropdown-item">
                        <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                        Settings
                    </router-link>

                    <div class="dropdown-divider"></div>

                    <router-link to="/access-share" class="dropdown-item">
                        <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                        Access share
                    </router-link>

                    <div class="dropdown-divider"></div>

                    <span class="dropdown-item" @click="logout">
                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                    </span>
                </div>
            </li>

        </ul>

    </nav>

</template>

<script>
import {get} from "lodash-es";
import jwt from "@/utils/jwt";
import {mapActions, mapState} from "vuex";

export default {
    name: "TopbarComponent",
    components: {},
    computed: {
        profileUrl() {
            return get(jwt.authUser(),'profile_image_url', 'https://cdn-icons-png.flaticon.com/512/847/847969.png');
        },
        displayName() {
            return jwt.authUser().display_name;
        },
        ...mapState({
            'actor': state => state.context.actor,
        }),
    },
    methods: {
        ...mapActions({
            'setActor': 'context/setActor' // map `this.add()` to `this.$store.dispatch('increment')`
        }),
        resetActor() {
            this.setActor(null);
            window.location.href = '/access-share';
        },
        logout() {
            jwt.clearAllCookies();
            this.$router.push({name: 'Login'});
        }
    }
}
</script>

<style scoped>

</style>
