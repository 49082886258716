<template>
    <div class="container-fluid mt-2">
        <table class="table table-bordered table-hover">
            <thead>
                <th class="align-middle">
                    Streamer name
                </th>
                <th class="text-right">
                    Actions
                </th>
            </thead>

            <tr v-for="(share, shareIndex) in shares" :key="shareIndex">
                <td class="align-middle">
                    {{ share.from_user }}
                </td>
                <td class="text-right">
                    <button class="btn btn-primary" @click="()=>{switchToActor(share)}" >
                        <i class="fa fa-fingerprint" />
                    </button>
                </td>
            </tr>
        </table>
    </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "MyAccessComponent",
    props: {
        shares: {
            required: true,
            type: Array
        }
    },
    methods: {
        ...mapActions({
            'setActor': 'context/setActor' // map `this.add()` to `this.$store.dispatch('increment')`
        }),
        switchToActor(id) {
            this.setActor(id);
            window.location.href = '/';
        }
    },
}
</script>

<style scoped>

</style>
