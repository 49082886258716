<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-cogs align-baseline mr-2"/>
                            <input class="form-control" placeholder="Action Title" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>

            </div>

            <div class="collapse" :class="{show:!collapsed}">
                <div class="card-body">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Command type
                            </span>
                        </div>
                        <select class="form-control" v-model="commandData.configuration.commandType">
                            <option value="command">Command</option>
                            <option value="action-group">Action group</option>
                        </select>
                    </div>
                    <div class="input-group mt-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Command
                            </span>
                        </div>
                        <select class="form-control" v-model="commandData.configuration.commandId">
                            <option disabled :value="null">Choose a command</option>
                            <option v-for="(command, index) in commands" :key="index" :value="command.id">{{command.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, merge} from "lodash-es";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import axios from "axios";
import CommandRunnerCommand from "@/command/CommandRunnerCommand";

export default {
    name: "CommandComponent",
    components: {CommandActionsComponent},
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null,
            commands:[],
        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        },
        'commandData.configuration.commandType': {
            handler() {
                this.updateCommandList();
            }
        }
    },
    methods:{
        updateCommandList(){
            axios.get(
                process.env.VUE_APP_BACKEND_URL+'/api/v1/commands?type='+this.commandData.configuration.commandType
            ).then(response => {
                this.commands = response.data.filter(command => command.id != this.$route.params.id);
            })
        }
    },
    mounted() {
        this.updateCommandList();
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new CommandRunnerCommand)[key] === 'object') {
                this.commandData[key] = merge((new CommandRunnerCommand)[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
    },
}
</script>

<style scoped>

</style>
