<template>
    <CommandEditorComponent title="Timer editor" :command-id="$route.params.id">
        <template v-slot:configure="{configuration, updateConfiguration}">
            <div>
                <div class="input-group input-group-sm mb-3 w-100">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Delay</span>
                    </div>
                    <input type="number" @change="updateConfiguration" :value="configuration.timer" title="timer" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">Seconds</span>
                    </div>
                </div>
            </div>
        </template>
    </CommandEditorComponent>
</template>

<script>

import CommandEditorComponent from "@/components/Command/CommandEditorComponent";

export default {
    name: "TimerEditView",
    components: {CommandEditorComponent},
}
</script>

<style scoped>

</style>
