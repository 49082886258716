<template>
    <div>
        <div v-if="loading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
            <table class="table" v-else-if="overlays.length">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col" class="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <OverlayListItemComponent v-for="(overlay, index) in overlays" :overlay="overlay" @delete="refresh" :key="index" />
                </tbody>
            </table>
        <div v-else>
            No overlays have been created yet!
        </div>
    </div>
</template>

<script>
import axios from "axios";
import OverlayListItemComponent from "@/components/viewSpecific/OverlayView/OverlayListItemComponent";

export default {
    name: "OverlayListComponent",
    components: {OverlayListItemComponent},
    data: () => {
        return {
            overlays: [],
            loading: true,
        }
    },
    computed:{

    },
    methods: {
        refresh(){
            axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/overlays').then((response)=>{
                this.overlays = response.data;
                this.loading = false;
            })
        }
    },
    mounted() {
        this.refresh();
    }
}
</script>

<style scoped>
    .chat-message:hover{
        background-color: #9147ff!important;
        color:white;
    }
</style>
