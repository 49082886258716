<template>
    <div>
        <div v-if="loading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <th class="w-50">
                        Name
                    </th>
                    <th class="w-50">
                        Value
                    </th>
                    <th>
                        Actions
                    </th>
                </thead>
                <VariableListItemComponent v-for="(variable, index) in variables" :variable="variable" :key="index" />
                <VariableListItemComponent :variable="{}" :key="newId" @refresh="refresh" />
            </table>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import VariableListItemComponent from "@/components/viewSpecific/VariableView/VariableListItemComponent";
import { uniqueId} from "lodash-es";

export default {
    name: "VariableListComponent",
    components: {VariableListItemComponent},
    data: () => {
        return {
            variables: [],
            loading: true,
            newId: null,
        }
    },
    methods: {
        refresh(){
            this.newId = uniqueId('new_');
            axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/variables').then((response)=>{
                this.variables = response.data;
                this.loading = false;
            })
        }
    },
    mounted() {
        this.refresh();
    }
}
</script>

<style scoped>
</style>
