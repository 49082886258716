<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-fingerprint align-baseline mr-2"/>
                            <input class="form-control" placeholder="Action Title" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, merge} from "lodash-es";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import SpecialIdentifierCommand from "@/command/SpecialIdentifierCommand";

export default {
    name: "ClipComponent",
    components: {CommandActionsComponent},
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null
        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        }
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new SpecialIdentifierCommand)[key] === 'object') {
                this.commandData[key] = merge((new SpecialIdentifierCommand)[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
    },
}
</script>

<style scoped>

</style>
