<template>
    <ServiceEditorComponent :command-id="$route.params.id" />
</template>

<script>
import ServiceEditorComponent from "@/components/Command/ServiceEditorComponent";

export default {
    name: "Service-Edit-View",
    components: {ServiceEditorComponent},
}
</script>

<style scoped>

</style>
