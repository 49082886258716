import { createStore, createLogger } from 'vuex'
import context from "@/store/modules/context";
import chat from "@/store/modules/chat";
const debug = process.env.NODE_ENV !== 'production'
import createPersistedState from "vuex-persistedstate";

let plugins = [
    createPersistedState({
        paths: ['chat.channelHistories','context']
    })
];
if(debug) {
    plugins.push(createLogger());
}

export default createStore({
    modules: {
        context,
        chat
    },
    strict: debug,
    plugins,
    state: {}
})
