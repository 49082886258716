import CommandBase from "@/command/CommandBase";

class ChatMessageCommand extends CommandBase{

    name = 'Chat Message';
    type = 'ChatMessage';
    hasNext = false;

    configuration = {
        message: "",
        sendViaUser: false,
    }

}

export default ChatMessageCommand;
