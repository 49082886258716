<template>
    <tr v-if="!deleted">
        <th scope="row">
            {{command.name}}
        </th>
        <td class="text-right">
            <button class="btn btn-primary m-1" v-if="!activeState" @click="install">
                <i class="fas fa-power-off"></i>
            </button>
            <button class="btn btn-danger m-1" v-if="activeState" @click="uninstall">
                <i class="fas fa-power-off"></i>
            </button>
            <button v-if="canEditService" class="btn btn-primary m-1" @click="edit">
                <i class="fas fa-pen"></i>
            </button>
            <button v-if="canEditService" class="btn btn-danger m-1" @click="remove">
                <i class="fas fa-trash-alt"></i>
            </button>
        </td>
    </tr>
</template>

<script>

import axios from "axios";
import User from "@/model/user";
import Role from "@/model/role";
import jwt from "@/utils/jwt";

export default {
    name: "ServiceListItemComponent",
    components: {},
    props: ['command'],
    data(){
        return {
            deleted: false,
            activeState: false,
        }
    },
    computed:{
        authUser() {
            return new User(jwt.authUser());
        },
        canEditService(){
            return this.authUser.hasRole(Role.SUPER_ADMIN)
        }
    },
    methods: {
        remove(){
            this.deleted = true;
            axios.delete(process.env.VUE_APP_BACKEND_URL+'/api/v1/services/'+this.command.id);
        },
        edit(){
            this.$router.push({path: '/services/'+this.command.id});
        },
        install(){
            axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/services/install/'+this.command.id).then(({data}) => {
                window.axios = axios;
                eval(data.script);
                this.activeState = true;
            });
        },
        uninstall(){
            axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/services/uninstall/'+this.command.id).then(()=>{
                this.activeState = false;
            });
        }
    },
    mounted() {
        this.activeState = this.command.active;
    }
}
</script>
