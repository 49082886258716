import CommandBase from "@/command/CommandBase";

class ClipCommand extends CommandBase{

    name = 'Clip';
    type = 'Clip';
    hasNext = false;

    configuration = {}
}

export default ClipCommand;
