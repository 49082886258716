<template>
    <div>
        <input v-model="username" />
        <button @click="addUser" class="btn btn-primary">Add</button>
        <table class="table">
            <thead>
            <tr>
                <th scope="col">Username</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user, index) in users" :key="index">
                <td>{{ user.username }}</td>
                <td class="text-right">

                </td>
            </tr>

            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name:'WhitelistView',
    data(){
        return {
            users: [],
            loading:true,
            username:"",
        }
    },
    methods: {
        addUser(){
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/whitelist', {username: this.username});
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/whitelist').then((response)=>{
            this.users = response.data;
            this.loading = false;
        })
    }
}
</script>

<style scoped>
    .img-profile{
        height:1.5em;
    }
    td.min {
        width: 1%;
        white-space: nowrap;
    }
</style>
