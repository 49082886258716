<template>
    <tr>
        <th scope="row">
            {{overlay.name}}
        </th>
        <td class="text-right">
            <button class="btn btn-primary m-1" @click="edit">
                <i class="fas fa-pen"></i>
            </button>
            <button class="btn btn-danger m-1" :disabled="deleting" :class="{'cursor-not-allowed':deleting}" @click="remove">
                <i class="fas fa-trash-alt"></i>
            </button>
        </td>
    </tr>
</template>

<script>

import axios from "axios";

export default {
    name: "OverlayListItemComponent",
    components: {},
    props: ['overlay'],
    data: ()=>{
        return {
            deleting: false,
        }
    },
    computed:{

    },
    methods: {
        remove(){
            this.deleting = true;
            axios.delete(process.env.VUE_APP_BACKEND_URL+'/api/v1/overlays/'+this.overlay.id).then(()=>{
                this.$emit('delete');
                this.deleting = false;
            })
        },
        edit(){
            this.$router.push({path: '/overlays/'+this.overlay.id});
        }
    },
    mounted() {

    }
}
</script>
