import CommandBase from "@/command/CommandBase";

class CommandCreatorCommand extends CommandBase{

    name = 'Command Creator';
    type = 'CommandCreator';
    hasNext = true;

    configuration = new CommandBase();

}

export default CommandCreatorCommand;
