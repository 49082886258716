<template>
    <div>
        <PageHeadingComponent title="Settings" />
        <hr />
        <div class="row">
            <div class="col-12">
                <h2 class="h4">Bot setting</h2>
                <button v-if="!hasBot" class="btn clickable" style="background-color: #9147ff; color:white; font-weight: bold; padding: 1em" @click="loginTheBot">
                    <svg type="color-fill-brand" width="15px" height="15px" version="1.1" viewBox="0 0 24 28" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 eNrElQ" style="margin-bottom: -3px;">
                        <g fill-rule="evenodd">
                            <path d="M19 6v6h-2V6h2zm-7 0h2v6h-2V6zM5 0L0 5v18h6v5l5-5h4l9-9V0H5zm17 13l-4 4h-4l-4 4v-4H6V2h16v11z"></path>
                            <path d="M18 17l4-4V2H6v15h4v4l4-4h4zM12 6h2v6h-2V6zm7 0h-2v6h2V6z" fill="#FFF"></path>
                        </g>
                    </svg>
                    Login with Twitch
                </button>

                <button v-else class="btn clickable" style="background-color: #9147ff; color:white; font-weight: bold; padding: 1em" @click="logoutTheBot">
                    <svg type="color-fill-brand" width="15px" height="15px" version="1.1" viewBox="0 0 24 28" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 eNrElQ" style="margin-bottom: -3px;">
                        <g fill-rule="evenodd">
                            <path d="M19 6v6h-2V6h2zm-7 0h2v6h-2V6zM5 0L0 5v18h6v5l5-5h4l9-9V0H5zm17 13l-4 4h-4l-4 4v-4H6V2h16v11z"></path>
                            <path d="M18 17l4-4V2H6v15h4v4l4-4h4zM12 6h2v6h-2V6zm7 0h-2v6h2V6z" fill="#FFF"></path>
                        </g>
                    </svg>
                    Logout from <i><b>{{ bot.display_name }}</b></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeadingComponent from "@/components/Section/PageHeadingComponent";
import jwt from "@/utils/jwt";
import {get} from "lodash-es";
export default {
    name:'Setting-View',
    components: {PageHeadingComponent},
    computed: {
        hasBot(){
            return get(jwt.decrypt().options, 'has_bot', false);
        },
        bot(){
            return jwt.decrypt().bot;
        }
    },
    methods:{
        loginTheBot() {
            window.open(process.env.VUE_APP_BACKEND_URL + '/login/bot', '_blank');
            console.log('login the bot')
        },
        logoutTheBot() {
            console.log('logout the bot')
        }
    }
}
</script>

<style scoped>

</style>
