import CommandBase from "@/command/CommandBase";
import axios from "axios";

class SpotifyCommand extends CommandBase{
    static canBeAddedCache;
    static canBeAddedFetching = false;
    name = 'Spotify';
    type = 'Spotify';
    hasNext = false;
    canBeAdded = async() => {
        if(SpotifyCommand.canBeAddedCache !== null) {
            return SpotifyCommand.canBeAddedCache;
        }
        if(SpotifyCommand.canBeAddedFetching === false) {
            SpotifyCommand.canBeAddedFetching = axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/services/byName/Spotify');
            let {data} = await SpotifyCommand.canBeAddedFetching;
            SpotifyCommand.canBeAddedCache = data.active;
            return data.active;
        } else {
            let {data} = await SpotifyCommand.canBeAddedFetching;
            return data.active;
        }

    };
    configuration = {
    }

}

export default SpotifyCommand;
