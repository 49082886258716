import CommandBase from "@/command/CommandBase";

class ChatMessageCommand extends CommandBase{

    name = 'Counter';
    type = 'Counter';
    hasNext = false;

    configuration = {
        name: "",
        math: "x+1",
    }

}

export default ChatMessageCommand;
