<template>
    <div :class='{"mt-2":(index!==0)}' v-for="(command, index) in commandData.commands" :key="index + '_' + refreshId">
        <component
            :is="getComponent(command)"
            :command="command"
            @change.self="e => updateCommandPart(e, index)"
            @delete="()=>{remove(index)}"
            @duplicate="()=>{duplicate(index)}"
            @moveUp="()=>{moveUp(index)}"
            @moveDown="()=>{moveDown(index)}"
            @play="()=>{play(index)}"
        />
    </div>
</template>

<script>
import ChatMessageComponent from "@/components/Command/Actions/ChatMessageComponent";
import ConditionalComponent from "@/components/Command/Actions/ConditionalComponent";
import SpecialIdentifierComponent from "@/components/Command/Actions/SpecialIdentifierComponent";
import WebRequestComponent from "@/components/Command/Actions/WebRequestComponent";
import WaitComponent from "@/components/Command/Actions/WaitComponent";
import axios from "axios";
import CommandRunnerComponent from "@/components/Command/Actions/CommandRunnerComponent";
import CounterComponent from "@/components/Command/Actions/CounterComponent";
import OverlayComponent from "@/components/Command/Actions/OverlayComponent";
import {cloneDeep} from "lodash-es";
import VariableReplacerComponent from "@/components/Command/Actions/VariableReplacerComponent";
import CommandCreatorComponent from "@/components/Command/Actions/CommandCreatorComponent";
import SpotifyComponent from "@/components/Command/Actions/SpotifyComponent";
import ClipComponent from "@/components/Command/Actions/ClipComponent";
import TextReplaceComponent from "@/components/Command/Actions/TextReplaceComponent";
import ModerationComponent from "@/components/Command/Actions/ModerationComponent";

export default {
    name: "CommandViewerComponent",
    props: ['modelValue'],
    data: () => {
        return {
            commandData: {commands: []},
            refreshId: 0
        }
    },
    computed: {
        depth(){
            return 1;
        }
    },
    methods: {
        getComponent(command) {
            switch(command.type) {
                case 'ChatMessage':
                    return ChatMessageComponent;
                case 'Conditional':
                    return ConditionalComponent;
                case 'SpecialIdentifier':
                    return SpecialIdentifierComponent;
                case 'WebRequest':
                    return WebRequestComponent;
                case 'Wait':
                    return WaitComponent;
                case 'CommandRunner':
                    return CommandRunnerComponent;
                case 'Counter':
                    return CounterComponent;
                case 'Overlay':
                    return OverlayComponent;
                case 'VariableReplacer':
                    return VariableReplacerComponent;
                case 'CommandCreator':
                    return CommandCreatorComponent;
                case 'Spotify':
                    return SpotifyComponent;
                case 'Clip':
                    return ClipComponent;
                case 'TextReplace':
                    return TextReplaceComponent;
                case 'Moderation':
                    return ModerationComponent;
                default:
                    console.error('Undefined command', command);
                    break;
            }
            return 'div';
        },
        updateCommandPart(part, index) {
            this.commandData.commands[index] = part;
            this.$emit('change', {target:{value:this.commandData}})
        },
        remove(index) {
            this.commandData.commands = this.commandData.commands.filter((v, i) => {
                return index !== i;
            });
            this.refreshId++;
        },
        moveUp(index) {
            if(index === 0){
                 return;
            }
            let current = this.commandData.commands[index];
            let above = this.commandData.commands[index-1];

            this.commandData.commands[index-1] = current;
            this.commandData.commands[index] = above;
            this.refreshId++;
        },
        moveDown(index) {
            if(index === this.commandData.commands.length-1){
                 return;
            }
            let current = this.commandData.commands[index];
            let under = this.commandData.commands[index+1];

            this.commandData.commands[index+1] = current;
            this.commandData.commands[index] = under;
            this.refreshId++;
        },
        duplicate(index) {
            let current = this.commandData.commands[index];
            this.commandData.commands.push(cloneDeep(current));
            this.refreshId++;
        },
        play(index) {
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/live', {
                commands: [this.commandData.commands[index]],
                text: '10'
            });
        }
    },
    mounted() {
        this.commandData = this.modelValue;
    }
}
</script>

<style>
    .card {
        border-color: rgba(171, 42, 7, 0.2) !important;
    }
</style>
