import cookie from 'cookiejs';
import {get} from 'lodash-es';
import axios from "axios";
import User from "@/model/user";

export default {
    hasValidToken() {
        var token = this.decrypt();

        if(token){
            return new Date(get(token,'expire_at', 0)) > new Date();
        }
        return false;
    },
    decrypt() {
        if (cookie.get('jwt')) {
            var splittedJwt = cookie.get('jwt').split('.');
            return JSON.parse(atob(splittedJwt[1]));
        }
        return null;
    },
    authUser() {
        return new User(get(this.decrypt(), 'user', {}));
    },
    update(jwt) {
        cookie.set('jwt', jwt, {domain: process.env.VUE_APP_COOKIE_DOMAIN, 'expires': 1});
        axios.defaults.headers.common['Authorization'] = 'Bearer '+jwt;
    },
    clearAllCookies(){
        let pieces = window.location.host.split('.').reverse();
        let cookieList = Object.keys(cookie.all());

        let domain = "";
        pieces.forEach((piece, index) => {
            if(index === 0) {
                domain = piece;
            } else {
                domain = piece + '.' + domain;
            }
            cookieList.forEach(cookieName=>{
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain}`;
            })
        })
    }
}
