<template>
    <div>
        Chat
        <ChatMessage v-for="(message, key) in channelHistory" :key="key" class="chat-message" :class="{'bg-gray-200':key%2, 'bg-gray-400':!(key%2)}"  :message="message"/>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ChatMessage from "@/components/Chat/ChatMessage";
import {get} from 'lodash-es';

export default {
    name: "ChatBoxComponent",
    components: {ChatMessage},
    computed:{
        ...mapState({
            'contextTwitchChannel': state => state.context.contextTwitchChannel,
            'channelHistories': state => state.chat.channelHistories,
        }),
        channelHistory() {
            return get(this.channelHistories,this.contextTwitchChannel, []);
        }
    },
    methods: {
        ...mapActions({
            'listenToChannel': 'chat/listenToChannel'
        })
    },
    mounted() {
        this.listenToChannel(this.contextTwitchChannel);
    }
}
</script>

<style scoped>
    .chat-message:hover{
        background-color: #9147ff!important;
        color:white;
    }
</style>
