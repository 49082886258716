<template>
    <tr v-if="!deleted">
        <th scope="row">
            {{command.name}}
        </th>
        <td>{{command.triggers}}</td>
        <td>
            TODO
        </td>
        <td class="text-right">
            <button class="btn btn-success m-1" @click="clone">
                <i class="fas fa-clone"></i> Clone
            </button>
            <button class="btn btn-primary m-1" v-if="canModify" @click="edit">
                <i class="fas fa-pen"></i>
            </button>
            <button class="btn btn-danger m-1" v-if="canModify" @click="remove">
                <i class="fas fa-trash-alt"></i>
            </button>
        </td>
    </tr>
</template>

<script>

import axios from "axios";
import User from "@/model/user";
import jwt from "@/utils/jwt";
import Role from "@/model/role";

export default {
    name: "CommunityCommandListItemComponent",
    components: {},
    props: ['command'],
    computed:{
        authUser() {
            return new User(jwt.authUser());
        },
        canModify() {
            return this.authUser.hasRole(Role.ADMIN);
        },
    },
    data(){
        return {
            deleted: false
        }
    },
    methods: {
        remove(){
            this.deleted = true;
            axios.delete(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/'+this.command.id);
        },
        clone(){
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/community/clone', {
                commandId: this.command.id
            });
        },
        edit(){
            this.$router.push({path: '/commands/'+this.command.id});
        }
    },
    mounted() {

    }
}
</script>
