<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-link align-baseline mr-2"/>
                            <input class="form-control" placeholder="Action Title" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>
            </div>

            <div class="collapse" :class="{show:!collapsed}">
                <div class="card-body">
                    <div class="input-group">
                        <div class="input-group-prepend">
                                <select class="input-group-text btn btn-sm" v-model="commandData.configuration.method">
                                    <option value="GET">GET</option>
                                    <option value="POST">POST</option>
                                    <option value="PUT">PUT</option>
                                    <option value="DELETE">DELETE</option>
                                </select>
                        </div>
                        <input type="text" class="form-control" v-model="commandData.configuration.url" placeholder="URL">
                    </div>
                    <div class="input-group mt-3">
                        <textarea class="w-100 form-control" placeholder="Json Body" :value="commandData.configuration.body" @change="jsonBodyChange"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, merge} from "lodash-es";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import WebRequestCommand from "@/command/WebRequestCommand";

export default {
    name: "WebRequestComponent",
    components: {CommandActionsComponent},
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null
        }
    },
    methods: {
        jsonBodyChange(e) {
            var obj = JSON.parse(e.target.value);
            this.commandData.configuration.body = JSON.stringify(obj, undefined, 4);
        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        }
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new WebRequestCommand)[key] === 'object') {
                this.commandData[key] = merge((new WebRequestCommand)[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
    },
}
</script>

<style scoped>

</style>
