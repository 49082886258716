<template>
    <div>
        <div v-if="loading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
            <table class="table" v-else-if="commands.length">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col" class="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <CommandListItemComponent v-for="(command, index) in commands" :command="command" :key="index" />
                </tbody>
            </table>
        <div v-else>
            No command have been created yet!
        </div>
    </div>
</template>

<script>
import axios from "axios";
import CommandListItemComponent from "@/components/viewSpecific/CommandView/CommandListItemComponent";

export default {
    name: "ActionGroupListComponent",
    components: {CommandListItemComponent},
    data: () => {
        return {
            commands: [],
            loading: true,
        }
    },
    computed:{

    },
    methods: {
    },
    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands?type=action-group').then((response)=>{
            this.commands = response.data;
            this.loading = false;
        })
    }
}
</script>

<style scoped>
</style>
