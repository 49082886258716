import HomeView from "@/views/HomeView";
import AboutView from "@/views/AboutView";
import LoginView from "@/views/LoginView";
import PublicLayout from "@/Layout/PublicLayout";
import ChatView from "@/views/ChatView";
import SettingView from "@/views/SettingView";
import CommandView from "@/views/CommandView";
import CommandEditView from "@/views/CommandEditView";
import ActionGroupView from "@/views/ActionGroupView";
import ActionGroupEditView from "@/views/ActionGroupEditView";
import UsersView from "@/views/admin/UsersView";
import store from "@/store";
import EventView from "@/views/EventView";
import EventEditView from "@/views/EventEditView";
import WhitelistView from "@/views/admin/WhitelistView";
import OverlayView from "@/views/OverlayView";
import OverlayEditView from "@/views/OverlayEditView";
import VariablesView from "@/views/VariablesView";
import ChannelPointView from "@/views/ChannelPointView";
import ChannelPointEditView from "@/views/ChannelPointEditView";
import TimerView from "@/views/TimerView";
import TimerEditView from "@/views/TimerEditView";
import WebhookView from "@/views/WebhookView";
import ServiceView from "@/views/ServiceView";
import ServiceEditView from "@/views/ServiceEditView";
import AccessShareView from "@/views/AccessShareView";
import CommunityCommandView from "@/views/CommunityCommandView";

let routes = [
    { path: '/', component: HomeView },
    { path: '/login', component: LoginView, name: 'Login', meta: { layout: PublicLayout } },
    { path: '/chat', component: ChatView },
    { path: '/commands', component: CommandView },
    { path: '/commands/:id', component: CommandEditView },
    { path: '/action-groups', component: ActionGroupView },
    { path: '/action-groups/:id', component: ActionGroupEditView },
    { path: '/timers', component: TimerView },
    { path: '/timers/:id', component: TimerEditView },
    { path: '/webhooks', component: WebhookView },
    { path: '/events', component: EventView },
    { path: '/events/:id', component: EventEditView },
    { path: '/channelpoints', component: ChannelPointView },
    { path: '/channelpoints/:id', component: ChannelPointEditView },
    { path: '/overlays', component: OverlayView },
    { path: '/overlays/:id', component: OverlayEditView },
    { path: '/services', component: ServiceView },
    { path: '/services/:id', component: ServiceEditView },
    { path: '/variables', component: VariablesView },
    { path: '/settings', component: SettingView },
    { path: '/about', component: AboutView },
    { path: '/access-share', component: AccessShareView },

    { path: '/community/commands', component: CommunityCommandView },

]

if(store.getters["context/authUser"].hasRole('admin')) {
    routes = [
        ...routes,
        { path: '/admin/users', component: UsersView },
        { path: '/admin/whitelist', component: WhitelistView },
    ]
}
export default routes;
