<template>
    <table class="table">
        <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col">Username</th>
            <th scope="col">Role</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in users" :key="index">
            <td class="min">
                <img class="img-profile rounded-circle" :src="user.profile_image_url" />
            </td>
            <td>{{ user.username }}</td>
            <td>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Options</label>
                    </div>
                    <select class="custom-select" :value="user.role" @change="e => {updateUser(user.id,'role', e.target.value)}">
                        <option selected>Choose...</option>
                        <option v-for="(role, roleIndex) in roles" :key="roleIndex" :value="role">{{ role }}</option>
                    </select>
                </div>
            </td>
        </tr>

        </tbody>
    </table>
</template>

<script>
import axios from "axios";
import Role from "@/model/role";

export default {
    name:'UsersView',
    data(){
        return {
            users: [],
            loading:true
        }
    },
    computed: {
        roles() {
            return Role.ROLES();
        }
    },
    methods: {
        updateUser(userId, key, value){
            let user = this.users.find(user => user.id === userId);
            user[key] = value;
            axios.put(process.env.VUE_APP_BACKEND_URL+'/api/v1/users/'+userId, {[key]:value});
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/users').then((response)=>{
            this.users = response.data;
            this.loading = false;
        })
    }
}
</script>

<style scoped>
    .img-profile{
        height:1.5em;
    }
    td.min {
        width: 1%;
        white-space: nowrap;
    }
</style>
