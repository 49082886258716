import {forEach} from "lodash-es";
import axios from "axios";
import moment from "moment";

class Command{
    id = null;
    commands = [];
    name = [];
    configuration = {};
    enabled_at = moment().toISOString();
    type = "command";
    triggers = "";
    is_community = false;
    automatic_update =  true;
    cloned_from =  null;

    constructor(attributes = []) {
        this._fill(attributes);
    }

    _fill(attributes = []) {
        forEach(attributes, (value, attribute)=> {
            this[attribute] = value;
        });
        return this;
    }

    toArray() {
        return {
            id: this.id,
            commands: this.commands,
            name: this.name,
            configuration: this.configuration,
            enabled_at: moment().toISOString(),
            type: this.type,
            triggers: this.triggers,
            is_community: this.is_community,
            automatic_update: this.automatic_update,
        }
    }

    save() {
        if(this.id === null){
            return this._create();
        } else {
            return this._update();
        }
    }

    async _create() {
        let response = await axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands', this.toArray());
        return this._fill(response.data.data);
    }

    async _update() {
        let response = await axios.put(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/'+this.id, this.toArray());
        return this._fill(response.data.data);
    }
}

export default Command;
