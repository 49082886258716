<template>
    <div>
        <h1>
            {{ pageTitle }}
            <span class="float-right" v-if="!loading">
                <button class="btn btn-primary" @click="copy">
                    <i class="fas fa-copy" />
                    Copy source
                </button>
                <a class="btn btn-primary ml-2" target="_blank" :href="overlayUrl">
                    <i class="fas fa-eye" />
                    View
                </a>
                <button class="btn btn-primary ml-2" @click="save">
                    <i class="fas fa-save" />
                    Save
                </button>
            </span>
        </h1>
        <template v-if="loading">

        </template>
        <template v-else>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group input-group-sm mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Name</span>
                        </div>
                        <input type="text" v-model="overlay.name" class="form-control">
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>

import axios from "axios";
import Overlay from "@/model/overlay";

export default {
    name: "OverlayEditView",

    data: () => {
        return {
            overlay: null,
            loading: true,
        }
    },
    computed: {
        pageTitle() {
            return this.overlay === null ? 'Overlay' : `Overlay: ${this.overlay?.name}`;
        },
        overlayUrl() {
            return process.env.VUE_APP_BACKEND_URL+'/overlays/'+this.$route.params.id;
        }
    },
    methods: {
        save() {
            this.overlay.save();
        },
        copy() {
            navigator.clipboard.writeText(this.overlayUrl);
        }
    },

    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/overlays/'+this.$route.params.id).then((response)=>{
            this.overlay = new Overlay(response.data);
            this.loading = false;
        })
    }
}
</script>

<style scoped>

</style>
