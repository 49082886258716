<template>
    <div>
        <h1>Dashboard</h1>

        <div class="card-deck mb-3 text-center">
            <div class="card mb-4 shadow-sm">
                <div class="card-header">
                    <h4 class="my-0 font-weight-normal">
                        Bot status
                        <label class="switch switch-sm" v-if="!bot_loading">
                            <input type="checkbox" :checked="has_bot" @change="e=>toggleBot(e.target.checked)">
                            <span class="slider"></span>
                        </label>
                    </h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title" v-if="!bot_loading">
                        {{ botStatus.label }}
                        <span class="btn-circle btn-sm" :style="{backgroundColor: botStatus.color}"/>
                    </h1>
                </div>
            </div>
            <div class="card mb-4 shadow-sm">
                <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Commands</h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title">{{ commandCount }}</h1>
                </div>
            </div>
            <div class="card mb-4 shadow-sm">
                <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Viewers</h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title">{{ viewers }}</h1>
                </div>
            </div>
        </div>

        <hr />
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters, mapState} from "vuex";
import {get} from "lodash-es";
export default {
    name: "Home-View",
    data: ()=> {
        return {
            features:[],
            commandCount:'WIP',
            viewers:'WIP',
            bot_status: 0,
            has_bot: 0,
            bot_loading: 1,
        }
    },
    computed: {
        ...mapGetters({
            'authUser': "context/authUser",
        }),
        ...mapState({
            'actor': state => state.context.actor,
        }),
        botStatus() {
            switch(this.bot_status) {
                case 1:
                    return {
                        label:`Online`,
                        color: 'green'
                    };
                case 2:
                    return {
                        label:`Connecting`,
                        color: 'Yellow'
                    };
                case 3:
                    return {
                        label:`Offline`,
                        color: 'Red'
                    };
                case 0:
                default:
                    if(!this.has_bot) {
                        return {
                            label:`Not active`,
                            color: 'Red'
                        };
                    }
                    return {
                        label:`Maintenance`,
                        color: 'Red'
                    };
            }
        }
    },
    created() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/features').then(result => {
            this.features = result.data;
        });

        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/bot').then(result => {
            this.bot_status = result.data.bot_status;
            this.has_bot = result.data.has_bot;
            this.bot_loading = 0;
        });
    },

    mounted() {
        const userId = get(this.actor, 'id', this.authUser.id);
        window.Echo.private(`bot.${userId}`)
            .listen('.BotUpdated', () => {
                axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/bot').then(result => {
                    this.bot_status = result.data.bot_status;
                    this.has_bot = result.data.has_bot;
                });
            });
    },

    methods: {
        toggleBot(newStatus) {
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/bot', {'has_bot':newStatus}).then();
        },
        toggleFeature(feature){
            this.authUser.toggleFeature(feature);
        },
        featureIsEnabled(feature) {
            return this.authUser.features.includes(feature)
        }
    }
}
</script>
