<template>
    <ul class="navbar-nav bg-dark sidebar sidebar-dark accordion" id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <router-link to="/" class="sidebar-brand d-flex align-items-center justify-content-center">
            <img src="@/assets/Nerdotron_Banner.png" style="max-width: 100%"/>
        </router-link>

        <!-- Divider -->
        <hr class="sidebar-divider mt-4"/>

        <div class="sidebar-heading">
            General
        </div>

        <!-- Nav Item - Dashboard -->
        <li class="nav-item active">
            <router-link to="/" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
            </router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading">
            Bot
        </div>
        <!-- Nav Item - Pages Collapse Menu -->
        <li class="nav-item">
<!--                <router-link to="/channel" class="nav-link collapsed" v-if="hasFeature('Channel')">-->
<!--                    <i class="fas fa-fw fa-cog"></i>-->
<!--                    <span>Channel</span>-->
<!--                </router-link>-->
            <router-link to="/commands" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Commands</span>
            </router-link>
            <router-link to="/channelpoints" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Channel Points</span>
            </router-link>
<!--                <router-link to="/chat" class="nav-link collapsed" v-if="hasFeature('Chat')">-->
<!--                    <i class="fas fa-fw fa-cog"></i>-->
<!--                    <span>Chat</span>-->
<!--                </router-link>-->
            <router-link to="/events" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Events</span>
            </router-link>

            <router-link to="/action-groups" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Action groups</span>
            </router-link>

            <router-link to="/timers" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Timers</span>
            </router-link>

            <router-link to="/overlays" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Overlays</span>
            </router-link>

            <router-link to="/services" class="nav-link collapsed" v-if="!actor">
                <i class="fas fa-fw fa-cog"></i>
                <span>Services</span>
            </router-link>

            <router-link to="/webhooks" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Webhooks</span>
            </router-link>

            <router-link to="/variables" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Variables</span>
            </router-link>
        </li>

        <div class="sidebar-heading">
            Community
        </div>
        <!-- Nav Item - Pages Collapse Menu -->
        <li class="nav-item">
<!--                <router-link to="/channel" class="nav-link collapsed" v-if="hasFeature('Channel')">-->
<!--                    <i class="fas fa-fw fa-cog"></i>-->
<!--                    <span>Channel</span>-->
<!--                </router-link>-->
            <router-link to="/community/commands" class="nav-link collapsed">
                <i class="fas fa-fw fa-cog"></i>
                <span>Commands</span>
            </router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">


        <template v-if="authUser.hasRole('admin')">
            <!-- Heading -->
            <div class="sidebar-heading">
                Admin
            </div>
            <!-- Nav Item - Pages Collapse Menu -->
            <li class="nav-item">
                <router-link to="/admin/users" class="nav-link collapsed" v-if="hasFeature('User Management')">
                    <i class="fas fa-fw fa-cog"></i>
                    <span>Users</span>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link to="/admin/whitelist" class="nav-link collapsed" v-if="hasFeature('Whitelist')">
                    <i class="fas fa-fw fa-cog"></i>
                    <span>Whitelist</span>
                </router-link>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider d-none d-md-block">
        </template>


    </ul>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "SidebarComponent",
    computed: {
        ...mapGetters({
            'authUser': "context/authUser",
        }),
        ...mapState({
            'actor': state => state.context.actor,
        }),
    },
    methods: {
        hasFeature(feature) {
            return this.authUser.features.includes(feature);
        }
    }
}
</script>

<style scoped>

</style>
