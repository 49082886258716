import CommandBase from "@/command/CommandBase";

class OverlayCommand extends CommandBase{

    name = 'Overlay';
    type = 'Overlay';
    hasNext = false;

    configuration = {
        type: "text",
        duration: "",
        targetOverlay: "",
        content: "",
        css: "",
        imgCss: "",
        volume: 100,
        rate: 100,
        pitch: 100,
        url: "",
        positionMode: 'simple',
        positionOption: {
            position: 5
        },
        imageOptions: {
            width: null,
            height: null,
        }
    }

}

export default OverlayCommand;
