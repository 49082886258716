import CommandBase from "@/command/CommandBase";

class ModerationCommand extends CommandBase{
    name = 'Moderation';
    type = 'Moderation';
    hasNext = false;
    configuration = {
        targetName: '',
        time: 600,
        reason: ''
    }

}

export default ModerationCommand;
