<template>
    <div>
        <img src="@/assets/Nerdotron_Banner.png" />
        <div style="margin:50px">
            <button class="btn clickable" style="background-color: #9147ff; color:white; font-weight: bold; padding: 1em" @click="login">
                <svg type="color-fill-brand" width="15px" height="15px" version="1.1" viewBox="0 0 24 28" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 eNrElQ" style="margin-bottom: -3px;">
                    <g fill-rule="evenodd">
                        <path d="M19 6v6h-2V6h2zm-7 0h2v6h-2V6zM5 0L0 5v18h6v5l5-5h4l9-9V0H5zm17 13l-4 4h-4l-4 4v-4H6V2h16v11z"></path>
                        <path d="M18 17l4-4V2H6v15h4v4l4-4h4zM12 6h2v6h-2V6zm7 0h-2v6h2V6z" fill="#FFF"></path>
                    </g>
                </svg>
                Login with Twitch
            </button>
        </div>
    </div>
</template>

<script>
import jwt from "@/utils/jwt";

export default {
    name: "LoginView",
    methods: {
        login(){
            jwt.clearAllCookies();
            window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
        }
    }
}
</script>

<style scoped>
    #app > div {
        margin:150px auto;
        padding: 3em;
        max-width:600px;
        border: solid 1px #313131;
        background-color: #fafafa;
        text-align: center;
        border-radius: 5px;
    }
</style>
