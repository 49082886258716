<template>
    <CommandEditorComponent :command-id="$route.params.id">
        <template v-slot:configure="{configuration, updateConfiguration}">
            <div>
                <div class="input-group input-group-sm mb-3 w-100">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Role</span>
                    </div>
                    <select @change="updateConfiguration" :value="configuration.role" title="role" class="form-control">
                        <option value="streamer">Streamer</option>
                        <option value="mod">Moderator</option>
                        <option value="vip">VIP</option>
                        <option value="follower">Follower</option>
                        <option value="viewer">Streamer</option>
                    </select>
                </div>
                <div class="input-group input-group-sm mb-3 w-100">
                    <div class="input-group-prepend">
                        <span class="input-group-text">User cooldown</span>
                    </div>
                    <input type="number" @change="updateConfiguration" :value="configuration.userCooldown" title="userCooldown" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">Seconds</span>
                    </div>
                </div>
                <div class="input-group input-group-sm mb-3 w-100">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Global cooldown</span>
                    </div>
                    <input type="number" @change="updateConfiguration" :value="configuration.globalCooldown" title="globalCooldown" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">Seconds</span>
                    </div>
                </div>
            </div>
        </template>
    </CommandEditorComponent>
</template>

<script>

import CommandEditorComponent from "@/components/Command/CommandEditorComponent";

export default {
    name: "Command-Edit-View",
    components: {CommandEditorComponent},
}
</script>

<style scoped>

</style>
