<template>
    <div>
        <h1>Timer</h1>
        <TimerListComponent />
        <div class="text-center">
            <button @click="createCommand" class="btn btn-primary">New timer</button>
        </div>

        <Teleport to="body">
            <div v-if="modalOpened" class="modal fade show" style="padding-right: 17px; display: block; background-color: rgba(0,0,0,0.5)">
                <div class="modal-dialog">
                    <div class="modal-content" v-if="!saving">
                        <div class="modal-header">
                            <h5 class="modal-title">Create a new timer</h5>
                            <button type="button" class="close">
                                <span @click="dismissModal">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Timer name</span>
                                </div>
                                <input type="text" class="form-control" v-model="command.name">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Delay (seconds)</span>
                                </div>
                                <input type="text" class="form-control" v-model="command.configuration.timer">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="dismissModal">Close</button>
                            <button type="button" class="btn btn-primary" @click="saveCommand">Save changes</button>
                        </div>
                    </div>
                    <div class="modal-content" v-else>
                        <div class="modal-body">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import Command from "@/model/command";
import TimerListComponent from "@/components/viewSpecific/TimerView/TimerListComponent";
export default {
    name: "Action-Group-View",
    components: {TimerListComponent},
    data: ()=>{
        return {
            modalOpened: false,
            saving: false,
            command: null,
        }
    },
    methods: {
        createCommand() {
            this.command = new Command({'type':'timer'});
            this.modalOpened = true;
        },
        async saveCommand() {
            this.saving = true
            this.command = await this.command.save();
            this.$router.push({path: '/timers/'+this.command.id});
        },
        dismissModal() {
            this.command = null;
            this.modalOpened = false;
        },
    }
}
</script>

<style scoped>

</style>
