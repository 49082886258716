import CommandBase from "@/command/CommandBase";

class ChatMessageCommand extends CommandBase{

    name = 'Command';
    type = 'CommandRunner';
    hasNext = false;

    configuration = {
        commandType: 'action-group',
        commandId: null,
    }

}

export default ChatMessageCommand;
