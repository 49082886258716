<template>
    <div>
        <div v-if="loading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
            <table class="table" v-else-if="commands.length">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col" class="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <ServiceListItemComponent v-for="(command, index) in commands" :command="command" :key="index" />
                </tbody>
            </table>
        <div v-else>
            No services have been created yet!
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ServiceListItemComponent from "@/components/viewSpecific/ServiceView/ServiceListItemComponent";
import User from "@/model/user";
import Role from "@/model/role";
import jwt from "@/utils/jwt";

export default {
    name: "ServiceListComponent",
    components: {ServiceListItemComponent},
    data: () => {
        return {
            commands: [],
            loading: true,
        }
    },
    computed:{
        authUser() {
            return new User(jwt.authUser());
        },
        canEditService(){
            return this.authUser.hasRole(Role.SUPER_ADMIN)
        }
    },
    methods: {
    },
    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/services').then((response)=>{
            this.commands = response.data;
            this.loading = false;
        })
    }
}
</script>
