// initial state
import jwt from "@/utils/jwt";
import {cloneDeep, forEach, get} from "lodash-es";
import axios from "axios";
import User from "@/model/user";

const state = () => ({
    contextTwitchChannel: null,
    authUser: {},
    actor: null
})

// getters
const getters = {
    authUser(state) {
        return state.authUser.id ? new User(state.authUser) : new User(jwt.authUser());
    }
}

// actions
const actions = {
    setDefaultContext ({ commit }) {
        commit('setContextTwitchChannel', get(jwt.decrypt(), 'user.username', null));
        commit('setAuthUser', jwt.authUser());

        window.Echo.private(`user.${jwt.authUser().id}`)
            .listen('.UserUpdated', () => {
                axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/refresh').then(response => {
                    jwt.update(response.data);
                    setTimeout(()=>{
                        commit('setAuthUser', jwt.authUser());
                    }, 100);
                });
            });
    },
    setActor({commit}, share) {
        commit('setActor', share);
    },
}

// mutations
const mutations = {
    setContextTwitchChannel (state, channel) {
        state.contextTwitchChannel = channel
        state.contextTwitchChannel = channel
    },
    setAuthUser (state, authUser) {
        if(!(authUser instanceof User)){
            authUser = new User(authUser);
        }

        state.authUser = authUser;
    },
    modifyUser (state, attributes) {
        var authUser = cloneDeep(state.authUser);
        forEach(attributes, (value, attribute) => {
            authUser[attribute] = value;
        });
        state.authUser = authUser;
        axios.put(process.env.VUE_APP_BACKEND_URL+'/api/v1/users/'+authUser.id, attributes);
    },
    setActor(state, share) {
        if(share && share.from_user_id !== state.authUser.id){
            state.actor = {id: share.from_user_id, username: share.from_user};
        } else {
            state.actor = null;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
