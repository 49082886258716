<template>
    <div class="container-fluid mt-2">
        <table class="table table-bordered table-hover">
            <thead>
                <th class="align-middle">
                    Streamer name
                </th>
                <th class="text-right">
                    Actions
                </th>
            </thead>

            <tr v-for="(share, shareIndex) in shares" :key="shareIndex">
                <td class="align-middle">
                    {{ share.to_user }}
                </td>
                <td class="text-right">
                    <button class="btn btn-danger" @click="()=>{removeShare(share.id)}">
                        <i class="fa fa-trash" />
                    </button>
                </td>
            </tr>
            <tr :key="draw">
                <td class="align-middle">
                    <div class="input-group">
                        <input class="form-control" placeholder="Username" v-model="input">
                    </div>
                </td>
                <td class="text-right">
                    <button class="btn btn-info" @click="addShare">
                        <i class="fa fa-save" />
                    </button>
                </td>
            </tr>
        </table>
    </div>

</template>

<script>
import axios from "axios";

export default {
    name: "MySharedAccessComponent",
    data() {
        return {
            draw:0,
            input:''
        }
    },
    watch: {
        shares() {
            this.draw++;
        }
    },
    props: {
        shares: {
            required: true,
            type: Array
        }
    },
    methods: {
        async addShare() {
            await axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/sharing', {username:this.input});
            this.input = '';
            this.$emit('refresh');
        },
        async removeShare(id) {
            await axios.delete(process.env.VUE_APP_BACKEND_URL+'/api/v1/sharing/'+id);
            this.$emit('refresh');
        }
    }
}
</script>

<style scoped>

</style>
