<template>
    <div>
        <div style="width:5px;height:100%;position:absolute;left:0;top:0;background-color: #6f42c1">

        </div>
        <h1>
            {{ pageTitle }}
            <span class="float-right" v-if="!loading">
                <span v-if="$slots.configure" class="position-relative">
                    <button class="btn btn-primary" @click="configure">
                        <i class="fas fa-cogs" />
                        Configure
                    </button>
                    <div
                        v-if="configurationOpened"
                        @focusout="configure"
                        class="position-absolute bg-gray-200 p-3 rounded"
                        style="z-index: 1; border: solid 1px black; width:25vw; right:0">
                        <slot name="configure" :configuration="command.configuration" :updateConfiguration="updateConfiguration"></slot>
                    </div>
                </span>

                <button class="btn btn-primary ml-2" @click="play">
                    <i class="fas fa-play" />
                    Play
                </button>

                <button class="btn btn-primary ml-2" @click="save">
                    <i class="fas fa-save" />
                    Save
                </button>
            </span>
        </h1>
        <template v-if="loading">

        </template>
        <template v-else>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group input-group-sm mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Name</span>
                        </div>
                        <input type="text" v-model="command.name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mt-2" v-if="hasTrigger">
                <div class="col-md-12">
                    <div class="input-group input-group-sm mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Triggers</span>
                        </div>
                        <input type="text" class="form-control" placeholder="Chat Trigger (space seperated)" v-model="command.triggers">
                    </div>
                </div>
            </div>

            <div class="row mt-2" v-if="command.cloned_from">
                <div class="col-md-12">
                    <div class="input-group input-group-sm mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Update from community</span>
                        </div>
                        <label class="switch">
                            <input type="checkbox" :checked="command.automatic_update" @change="e=>autoUpdate(e.target.checked)">
                            <span class="slider"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-2" style="min-height: 50vh">
                <div class="col-md-12">
                    <div class="bg-dark rounded p-2 h-100 ">
                        <CommandViewerComponent v-model="command" />
                    </div>
                </div>
            </div>

            <div class="row align-bottom mt-2">
                <div class="col-md-6">
                    <CommandActionSelector class="btn-dark" :value="selectedAddAction" @input="e => {selectedAddAction = e.target.value}" />
                    <button class="btn btn-primary ml-2" @click="addAction"><i class="fas fa-plus"></i></button>
                </div>
            </div>
        </template>

        <Teleport to="body">
            <div v-if="autoUpdateWarningModal" class="modal fade show" style="padding-right: 17px; display: block; background-color: rgba(0,0,0,0.5)">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Warning</h5>
                            <button type="button" class="close">
                                <span @click="autoUpdateWarningModal = false">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="input-group mb-3">
                                <p>
                                    Modifying a community command will disable automatic update.
                                </p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="autoUpdateWarningModal = false">Go back</button>
                            <button type="button" class="btn btn-primary" @click="e=>{autoUpdate(false);autoUpdateWarningModal = false}">I understand</button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import axios from "axios";
import Command from "@/model/command";
import CommandViewerComponent from "@/components/Command/CommandViewerComponent";
import CommandActionSelector from "@/components/Command/CommandActionSelector";

export default {
    name: "CommandEditorComponent",
    components: {CommandViewerComponent, CommandActionSelector},
    props: ['commandId', 'title'],
    data: () => {
        return {
            command: null,
            loading: true,
            selectedAddAction: null,
            configurationOpened: false,
            autoUpdateWarningModal: false,
            autoUpdateWarningActive: true,
        }
    },
    watch: {
        command: {
            deep:true,
            handler(newValue, oldValue) {
                if(
                    oldValue &&
                    newValue &&
                    this.autoUpdateWarningActive &&
                    newValue.automatic_update &&
                    !newValue.is_community
                ) {
                    this.command = oldValue;
                    this.autoUpdateWarningModal = true;
                }
            }
        }
    },
    computed: {
        pageTitle() {
            return this.title ?? 'Commands Editor';
        },
        hasTrigger() {
            return this.command.type === 'command';
        },
    },
    methods: {
        updateConfiguration(e){
            if(Array.isArray(this.command.configuration)){
                this.command.configuration = {};
            }
            this.command.configuration[e.target.title] = e.target.value;
        },
        addAction(){
            this.command.commands.push(new this.selectedAddAction.component);
        },
        play() {
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/live', {
                commands: this.command.commands,
                text: 'friends'
            });
        },
        save() {
            this.command.save();
        },
        configure() {
            this.configurationOpened = !this.configurationOpened;
        },
        autoUpdate(val) {
            this.command.automatic_update = val;
            this.$nextTick(()=>{
                this.autoUpdateWarningActive = val;
            })
        },
    },

    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/'+this.commandId).then((response)=>{
            this.command = new Command(response.data);
            console.log(this.command);
            this.autoUpdateWarningActive = this.command.cloned_from && this.command.automatic_update
            this.loading = false;
        })
    }
}
</script>

<style scoped>

</style>
