<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-project-diagram align-baseline mr-2"/>
                            <input class="form-control" placeholder="Action Title" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>
            </div>

            <div class="collapse" :class="{show:!collapsed}">
                <div class="bg-gray-400 container-fluid p-3">
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Name
                                    </span>
                                </div>
                                <input type="text" class="form-control" v-model="commandData.configuration.name" placeholder="Name">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="isVisibleOption">
                        <div class="col-md-12">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Visible
                                    </span>
                                </div>
                                <select class="form-control" v-model="commandData.configuration.is_visible">
                                    <option :value="true">True</option>
                                    <option :value="false">False</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                            <span class="input-group-text">
                                Type
                            </span>
                                </div>
                                <select class="form-control" v-model="commandData.configuration.type">
                                    <option value="command">Command</option>
                                    <option value="webhook">Webhook</option>
                                    <option value="event">Event</option>
                                    <option value="action-group">Action group</option>
                                    <option value="timer">Timer</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" v-for="(config,index) in getCommandConfigs" :key="index">
                        <div class="col-md-12" v-if="config === 'triggers'">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Triggers
                                    </span>
                                </div>
                                <input type="text" class="form-control" v-model="commandData.configuration.triggers" placeholder="Triggers">
                            </div>
                        </div>
                        <div class="col-md-12" v-if="config === 'timer'">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Delay
                                    </span>
                                </div>
                                <input type="number" class="form-control" :value="timer" @input="e=>updateConfiguration('timer',e.target.value)" placeholder="Delay">
                            </div>
                        </div>
                        <div class="col-md-12" v-if="config === 'event'">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        Event
                                    </span>
                                </div>
                                <select class="form-control" v-model="commandData.configuration.triggers">
                                    <option v-for="(event, i) in events" :key="i" :value="event.key">{{ event.label }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-md-12">
                            <CommandActionSelector :value="selectedNextCommand" @input="e => {selectedNextCommand = e.target.value}" class="btn-dark"/>
                            <button class="btn btn-primary ml-2" @click="addAction"><i class="fas fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <CommandViewerComponent v-model="commandData.configuration.next" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, map, merge, set} from "lodash-es";
import CommandActionSelector from "@/components/Command/CommandActionSelector";
import {defineAsyncComponent} from "vue";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import Events from "@/model/events";
import User from "@/model/user";
import Role from "@/model/role";
import jwt from "@/utils/jwt";
import CommandCreatorCommand from "@/command/CommandCreatorCommand";

export default {
    name: "CommandCreatorComponent",
    components: {
        CommandActionsComponent,
        CommandActionSelector,
        "CommandViewerComponent": defineAsyncComponent(() => import('@/components/Command/CommandViewerComponent.vue'))
    },
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null,
            selectedNextCommand: null,
        }
    },
    computed: {
        timer() {
            return this.commandData.configuration.configuration.timer;
        },
        authUser() {
            return new User(jwt.authUser());
        },
        isVisibleOption(){
            return this.authUser.hasRole(Role.ADMIN)
        },
        events() {
            return map(Events.events, (v, k) => {
                return {
                    'key': k,
                    'label': v
                };
            })
        },
        getCommandConfigs(){
            switch (this.commandData.configuration.type) {
                case 'command':
                    return ['triggers'];
                case 'event':
                    return ['event'];
                case 'webhook':
                case 'action-group':
                    return [];
                case 'timer':
                    return ['timer'];
            }
            return [];
        }
    },
    methods: {
        addAction(){
            if(this.selectedNextCommand){
                this.commandData.configuration.next.commands.push(new this.selectedNextCommand.component);
            }
        },
        updateConfiguration(key, value) {
            set(this.commandData,`configuration.configuration.${key}`, value);
        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        }
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new CommandCreatorCommand)[key] === 'object') {
                this.commandData[key] = merge((new CommandCreatorCommand())[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
    },
}
</script>

<style scoped>

</style>
