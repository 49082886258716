import CommandBase from "@/command/CommandBase";

class SpecialIdentifierCommand extends CommandBase{

    name = 'Special Identifier';
    type = 'SpecialIdentifier';
    hasNext = false;

    configuration = {
        specialIdentifier: "",
        replacement: "",
    }

}

export default SpecialIdentifierCommand;
