class Role {
    static SUPER_ADMIN = 'superAdmin';
    static ADMIN = 'admin';
    static BETA = 'beta';
    static PREMIUM = 'premium';
    static REGULAR = 'regular';

    static ROLES() {
        return [
            Role.SUPER_ADMIN,
            Role.ADMIN,
            Role.BETA,
            Role.PREMIUM,
            Role.REGULAR
        ];
    }

    static role_has_access_to_role($role, $requestAccessRole){
        if($role === null) {
            return false;
        }
        return Role.ROLES().indexOf($role) <= Role.ROLES().indexOf($requestAccessRole);
    }


}


export default Role;
