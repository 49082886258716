import CommandBase from "@/command/CommandBase";

class WaitCommand extends CommandBase{

    name = 'Wait';
    type = 'Wait';
    hasNext = false;

    configuration = {
        delay: 0,
    }

}

export default WaitCommand;
