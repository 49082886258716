<template>
    <div>
        <h1>
            {{ pageTitle }}
            <span class="float-right" v-if="!loading">
                <span v-if="$slots.configure" class="position-relative">
                    <button class="btn btn-primary" @click="configure">
                        <i class="fas fa-cogs" />
                        Configure
                    </button>
                    <div
                        v-if="configurationOpened"
                        @focusout="configure"
                        class="position-absolute bg-gray-200 p-3 rounded"
                        style="z-index: 1; border: solid 1px black; width:25vw; right:0">
                        <slot name="configure" :configuration="command.configuration" :updateConfiguration="updateConfiguration"></slot>
                    </div>
                </span>

                <button class="btn btn-primary ml-2" @click="play">
                    <i class="fas fa-play" />
                    Play
                </button>
                <button class="btn btn-primary ml-2" @click="save">
                    <i class="fas fa-save" />
                    Save
                </button>
            </span>
        </h1>
        <template v-if="loading">

        </template>
        <template v-else>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group input-group-sm mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Name</span>
                        </div>
                        <input type="text" v-model="command.name" class="form-control">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="input-group input-group-sm mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Script</span>
                        </div>
                        <PrismEditor v-model="command.script" class="form-control js-editor" :highlight="highlighter" />
                    </div>
                </div>
            </div>

            <div class="row mt-2" style="min-height: 50vh">
                <div class="col-md-12">
                    <div class="bg-dark rounded p-2 h-100 ">
                        <CommandViewerComponent v-model="command" />
                    </div>
                </div>
            </div>

            <div class="row align-bottom mt-2">
                <div class="col-md-6">
                    <CommandActionSelector class="btn-dark" :value="selectedAddAction" @input="e => {selectedAddAction = e.target.value}" />
                    <button class="btn btn-primary ml-2" @click="addAction"><i class="fas fa-plus"></i></button>
                </div>

            </div>
        </template>

    </div>
</template>

<script>
import axios from "axios";
import CommandViewerComponent from "@/components/Command/CommandViewerComponent";
import CommandActionSelector from "@/components/Command/CommandActionSelector";
import Service from "@/model/service";
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
    name: "ServiceEditorComponent",
    components: {CommandViewerComponent, CommandActionSelector, PrismEditor},
    props: ['commandId', 'title'],
    data: () => {
        return {
            command: null,
            loading: true,
            selectedAddAction: null,
            configurationOpened: false
        }
    },
    computed: {
        pageTitle() {
            return this.title ?? 'Service Editor';
        },
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.js);
        },
        updateConfiguration(e){
            if(Array.isArray(this.command.configuration)){
                this.command.configuration = {};
            }
            this.command.configuration[e.target.title] = e.target.value;
        },
        addAction(){
            this.command.commands.push(new this.selectedAddAction.component);
        },
        save() {
            this.command.save();
        },
        configure() {
            this.configurationOpened = !this.configurationOpened;
        },
        play() {
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/commands/live', {
                commands: this.command.commands
            });
            window.axios = axios;
            eval(this.command.script);
        },
    },

    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/services/'+this.commandId).then((response)=>{
            this.command = new Service(response.data);
            this.loading = false;
        })
    }
}
</script>

<style>
    .js-editor {
        /* you must provide font-family font-size line-height. Example: */
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        font-size: 14px;
        line-height: 1.5;
        min-height:200px!important;
    }
    .prism-editor__textarea:focus {
        outline: none;
    }
    .prism-editor__container{
        height:100%;
    }
    .prism-editor-wrapper .prism-editor__container{
        overflow: auto;
    }
</style>
