<template>
    <div class="row">
        <div class="col-auto font-weight-bolder"> {{message.display_name}}: </div>
        <div class="col-auto font-weight-bold">{{message.message}}</div>
    </div>
</template>

<script>
export default {
    name: "ChatMessage",
    props: {
        message: {
            required: true,
            type: Object
        }
    }
}
</script>

<style scoped>

</style>
