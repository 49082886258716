<template>
    <div class="accordion">
        <div class="card">
            <div class="card-header" @click.self="collapsed = !collapsed">
                <div class="mb-0 row" @click.self="collapsed = !collapsed">
                    <div class="col-md-4">
                        <div class="input-group">
                            <i class="fas fa-2x fa-tv align-baseline mr-2"/>
                            <input class="form-control" placeholder="Overlay" v-model="commandData.name"/>
                        </div>
                    </div>
                    <CommandActionsComponent
                        class="col-md-8 text-right"
                        @click.self="collapsed = !collapsed"
                    />
                </div>
            </div>

            <div class="collapse" :class="{show:!collapsed}">
                <div class="card-body">

                    <div class="input-group mt-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Type
                            </span>
                        </div>
                        <select class="form-control" v-model="commandData.configuration.type">
                            <option value="text">Text</option>
                            <option value="image">Image</option>
                            <option value="sound">Sound</option>
                            <option value="tts">Text-To-Speech</option>
                        </select>
                    </div>

                    <div class="input-group mt-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Overlay
                            </span>
                        </div>
                        <select class="form-control" v-model="commandData.configuration.targetOverlay">
                            <option v-if="!commandData.configuration.targetOverlay"/>
                            <option v-for="(overlay, key) in overlays" :key="key" :value="overlay.id" >{{ overlay.name }}</option>
                        </select>
                    </div>

                    <div class="input-group mt-2" v-if="hasDuration">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Duration
                            </span>
                        </div>
                        <input type="number" class="form-control" v-model="commandData.configuration.duration" />
                    </div>

                    <div class="input-group mt-2" v-if="['image','sound'].includes(commandData.configuration.type)">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Url
                            </span>
                        </div>
                        <input type="text" class="form-control" v-model="commandData.configuration.url" placeholder="URL">
                        <div class="input-group-append">
                            <button class="btn btn-primary" @click="selectUpload">
                                <input type="file" ref="file" :value="file" hidden :accept="uploadType" @change="upload" />
                                Upload <i class="fas fa-upload"/>
                            </button>
                        </div>
                    </div>

                    <div class="input-group mt-2" v-if="commandData.configuration.type === 'sound'">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Volume
                            </span>
                        </div>
                        <input class="form-control mx-4" type="range" min="1" max="100" v-model="commandData.configuration.volume">
                        <div class="input-group-append">
                            <input class="form-control" type="number" v-model="commandData.configuration.volume"/>
                        </div>
                    </div>

                    <div class="input-group mt-2" v-if="commandData.configuration.type === 'text'">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                HTML
                            </span>
                        </div>
                        <textarea class="form-control" placeholder="Text..." v-model="commandData.configuration.content"/>
                    </div>

                    <template v-if="hasPosition">
                        <hr />

                        <h5> Position </h5>
                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                Mode
                            </span>
                            </div>
                            <select class="form-control" v-model="commandData.configuration.positionMode">
                                <option value="simple">Simple</option>
<!--                                <option value="percent">Percentage</option>-->
<!--                                <option value="pixel">Pixels</option>-->
                            </select>
                        </div>
                        <div v-if="commandData.configuration.positionMode === 'simple'" class="mt-2" style="width:10em">
                            <table class="table-bordered border-success">
                                <tr>
                                    <td>
                                        <button @click="setPositionOptionSimple(1)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 1}">
                                            <i class="fa fa-arrow-up" style="transform: rotate(-45deg)"/>
                                        </button>
                                    </td>
                                    <td>
                                        <button @click="setPositionOptionSimple(2)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 2}">
                                            <i class="fa fa-arrow-up" />
                                        </button>
                                    </td>
                                    <td>
                                        <button @click="setPositionOptionSimple(3)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 3}">
                                            <i class="fa fa-arrow-up" style="transform: rotate(45deg)"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button @click="setPositionOptionSimple(4)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 4}">
                                            <i class="fa fa-arrow-left" />
                                        </button>
                                    </td>
                                    <td>
                                        <button @click="setPositionOptionSimple(5)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 5}">
                                            <i class="fas fa-compress-arrows-alt"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button @click="setPositionOptionSimple(6)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 6}">
                                            <i class="fa fa-arrow-right" />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button @click="setPositionOptionSimple(7)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 7}">
                                            <i class="fa fa-arrow-down" style="transform: rotate(45deg)"/>
                                        </button>
                                    </td>
                                    <td>
                                        <button @click="setPositionOptionSimple(8)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 8}">
                                            <i class="fa fa-arrow-down" />
                                        </button>
                                    </td>
                                    <td>
                                        <button @click="setPositionOptionSimple(9)" class="btn" :class="{'btn-primary': commandData.configuration.positionOption.position === 9}">
                                            <i class="fa fa-arrow-down" style="transform: rotate(-45deg)"/>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </template>

                    <template v-if="commandData.configuration.type === 'image'">
                        <hr />

                        <h5> Size </h5>
                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Width
                                </span>
                            </div>
                            <input class="form-control" v-model="commandData.configuration.imageOptions.width" />
                        </div>
                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Height
                                </span>
                            </div>
                            <input class="form-control" v-model="commandData.configuration.imageOptions.height" />
                        </div>
                    </template>

                    <template v-if="commandData.configuration.type === 'tts'">
                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                Text
                            </span>
                            </div>
                            <textarea class="form-control" placeholder="Text..." v-model="commandData.configuration.content"/>
                        </div>

                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                Voice
                            </span>
                            </div>
                            <select class="form-control" v-model="commandData.configuration.voice">
                                <option :value="voice.name" :key="voiceKey" v-for="(voice, voiceKey) in voicelist">{{voice.name}}</option>
                            </select>
                        </div>

                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                Volume
                            </span>
                            </div>
                            <input class="form-control mx-4" type="range" min="0" max="200" v-model="commandData.configuration.volume">
                            <div class="input-group-append">
                                <input class="form-control" type="number" v-model="commandData.configuration.volume"/>
                            </div>
                        </div>

                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                &nbsp;&nbsp;Pitch&nbsp;&nbsp;
                            </span>
                            </div>
                            <input class="form-control mx-4" type="range" min="0" max="200" v-model="commandData.configuration.pitch">
                            <div class="input-group-append">
                                <input class="form-control" type="number" v-model="commandData.configuration.pitch"/>
                            </div>
                        </div>

                        <div class="input-group mt-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                &nbsp;&nbsp;Rate&nbsp;&nbsp;
                            </span>
                            </div>
                            <input class="form-control mx-4" type="range" min="0" max="200" v-model="commandData.configuration.rate">
                            <div class="input-group-append">
                                <input class="form-control" type="number" v-model="commandData.configuration.rate"/>
                            </div>
                        </div>

                        <hr/>

                        <div class="text-right">
                            <i>TTS functionality provided by <a href="https://responsivevoice.org">ResponsiveVoice</a></i>
                        </div>
                    </template>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forEach, merge} from "lodash-es";
import CommandActionsComponent from "@/components/Command/CommandActionsComponent";
import axios from "axios";
import OverlayCommand from "@/command/OverlayCommand";

export default {
    name: "OverlayComponent",
    components: {CommandActionsComponent},
    props: {
        command: {
            required: true,
            type: Object
        }
    },
    data: () => {
        return {
            collapsed: false,
            commandData: null,
            overlays: [],
            file: null,
            voicelist: []
        }
    },
    computed: {
        uploadType() {
            switch(this.commandData.configuration.type) {
                case "sound":
                    return 'audio/*';

                case "image":
                    return 'image/*';

                default:
                    return null;
            }
        },
        hasDuration() {
            return !(['tts'].includes(this.commandData.configuration.type));
        },
        hasPosition() {
            return !(['tts', 'sound'].includes(this.commandData.configuration.type));
        },

    },
    methods: {
        selectUpload() {
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },
        upload() {
            let formData = new FormData();
            formData.append("file", this.$refs.file.files[0]);
            axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/medias', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({data})=>{
                this.commandData.configuration.url = process.env.VUE_APP_BACKEND_URL+'/medias/'+data.data.id;
            })
        },
        updateCssImg() {
            let css = '';
            if(this.commandData.configuration.imageOptions.width) {
                css += `width: ${this.toCssValue(this.commandData.configuration.imageOptions.width)};`;
            }
            if(this.commandData.configuration.imageOptions.height) {
                css += `height: ${this.toCssValue(this.commandData.configuration.imageOptions.height)};`;
            }
            this.commandData.configuration.imgCss = css;
        },
        toCssValue(value) {
            if(parseInt(value) === (value * 1)) {
                return `${value}px`;
            }
            return value;
        },
        setPositionOptionSimple(id) {
            this.commandData.configuration.positionOption.position = id;
            let css = `display: flex; height:100vh;`;

            if ([1,4,7].includes(id)) {
                css += 'justify-content: left;'
            } else if ([2,5,8].includes(id)) {
                css += 'justify-content: center;'
            } else if ([3,6,9].includes(id)) {
                css += 'justify-content: right;'
            }

            if ([1,2,3].includes(id)) {
                css += 'align-items: start;'
            } else if ([4,5,6].includes(id)) {
                css += 'align-items: center;'
            } else if ([7,8,9].includes(id)) {
                css += 'align-items: end;'
            }


            this.commandData.configuration.css = css;

        }
    },
    watch: {
        'commandData': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== null) {
                    this.$emit('change', newValue);
                }
            }
        },
        'commandData.configuration.type': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== newValue && oldValue !== null) {
                    this.commandData.configuration.url = "";
                    this.commandData.configuration.volume = 100;
                    this.commandData.configuration.css = "";
                    this.commandData.configuration.content = "";
                }
            }
        },
        'commandData.configuration.imageOptions.height': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== newValue && oldValue !== null) {
                    this.updateCssImg();
                }
            }
        },
        'commandData.configuration.imageOptions.width': {
            deep: true,
            handler(newValue, oldValue) {
                if(oldValue !== newValue && oldValue !== null) {
                    this.updateCssImg();
                }
            }
        }
    },
    beforeMount() {
        this.commandData = {};
        forEach(this.command, (attribute, key) => {
            if(typeof (new OverlayCommand)[key] === 'object') {
                this.commandData[key] = merge((new OverlayCommand)[key], attribute);
            } else {
                this.commandData[key] = attribute;
            }
        });
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/overlays').then((response)=>{
            this.overlays = response.data;
        })

        let voiceListInterval = setInterval(()=>{
            if(typeof window.responsiveVoice !== 'undefined') {
                this.voicelist = window.responsiveVoice.getVoices();
                clearInterval(voiceListInterval);
            }
        }, 50);
    },
}
</script>

<style scoped>

</style>
