<template>
        <select class="btn text-left" @change="change">
            <option value="" v-if="value === null" :selected="true">Action...</option>
            <option v-for="(option, index) in options" :key="index"
                    :value="option.id"
            >
                {{ option.label }}
            </option>
        </select>
</template>

<script>
import Commands from "@/command";
import {forEach} from "lodash-es";

export default {
    name: "CommandActionSelector",
    props: ['value'],
    data: () => {
        return {
            options: [],
        }
    },
    methods: {
        change(e){
            let value = this.options.find(option => e.target.value === option.id);
            this.$emit('input', {target:{value}});
        }
    },
    mounted() {
        forEach(Commands, async (commandClass)=> {
            let command = new commandClass;
            if(typeof command.canBeAdded === 'function') {
                command.canBeAdded = command.canBeAdded();
                if(command.canBeAdded instanceof Promise) {
                    command.canBeAdded = await command.canBeAdded;
                }
            }

            if(command.canBeAdded === true || typeof command.canBeAdded === 'undefined'){
                this.options.push({
                    id: command.type,
                    label: command.name,
                    component: commandClass
                })
            }

        })
    }
}
</script>

<style scoped>

</style>
