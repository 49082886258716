<template>
    <div>
        <h1>Channel Points</h1>
        <ChannelPointListComponent :existing-commands="existingCommands" />
        <div class="text-center">
            <button @click="createCommand" class="btn btn-primary mr-2">New Redeem</button>
            <button @click="createCommandFromExisting" class="btn btn-primary ml-2">Existing Redeem</button>
        </div>

        <Teleport to="body">
            <div v-if="modalOpened" class="modal fade show" style="padding-right: 17px; display: block; background-color: rgba(0,0,0,0.5)">
                <div class="modal-dialog">
                    <div class="modal-content" v-if="!saving">
                        <div class="modal-header">
                            <h5 class="modal-title">Create a new action</h5>
                            <button type="button" class="close">
                                <span @click="dismissModal">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Action name</span>
                                </div>
                                <input type="text" class="form-control" v-model="command.name">
                            </div>
                            <template v-if="fromExisting">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Redeem name</span>
                                    </div>
                                    <select  class="form-control" v-model="command.triggers">
                                        <option v-for="(event, i) in existingCommands" :key="i" :value="event.key">{{ event.label }}</option>
                                    </select>
                                </div>
                            </template>
                            <template v-else>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Redeem name</span>
                                    </div>
                                    <input class="form-control" v-model="newEventName">
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Redeem price</span>
                                    </div>
                                    <input type="number" class="form-control" v-model="newEventPrice">
                                </div>


                            </template>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="dismissModal">Close</button>
                            <button type="button" class="btn btn-primary" @click="saveCommand">Save changes</button>
                        </div>
                    </div>
                    <div class="modal-content" v-else>
                        <div class="modal-body">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import Command from "@/model/command";
import {map} from "lodash-es";
import axios from "axios";
import ChannelPointListComponent from "@/components/viewSpecific/ChannelPointView/ChannelPointListComponent";

export default {
    name: "ChannelPointView",
    components: {ChannelPointListComponent},
    data: ()=>{
        return {
            modalOpened: false,
            saving: false,
            command: null,
            fromExisting: false,
            existingCommands: [],
            newEventName: "",
            newEventPrice: 1
        }
    },
    methods: {
        createCommand() {
            this.command = new Command(Object.assign({'type':'ChannelChannelPointsCustomRewardRedemptionAdd'}, this.command));
            this.modalOpened = true;
        },
        createCommandFromExisting() {
            this.fromExisting = true;
            this.createCommand();
        },
        async saveCommand() {
            this.saving = true

            if(!this.fromExisting) {
                let {data} = await axios.post(process.env.VUE_APP_BACKEND_URL+'/api/v1/channel-points', {
                    title: this.newEventName,
                    cost: this.newEventPrice
                });

                this.command.triggers = data.data[0].id;
            }

            this.command = await this.command.save();
            this.$router.push({path: '/channelpoints/'+this.command.id});
        },
        dismissModal() {
            this.newEventName = "";
            this.fromExisting = false;
            this.command = null;
            this.modalOpened = false;
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL+'/api/v1/channel-points').then(({data})=>{
            this.existingCommands = map(data.data, (v) => {
                return {
                    key: v.id,
                    label: v.title
                }
            })
        })
    }
}
</script>

<style scoped>

</style>
