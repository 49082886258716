import {cloneDeep, forEach} from "lodash-es";
import store from "@/store";
import Role from "@/model/role";

class User{
    id = null;
    username = null;
    twitch_id = null;
    display_name = null;
    profile_image_url = null;
    broadcaster_type = null;
    bot_id = null;
    created_at = null;
    updated_at = null;
    role = null;
    has_bot = false;
    features = [];
    options = [];
    bot_status = 0;

    constructor(attributes = []) {
        forEach(attributes, (value, attribute)=> {
            this[attribute] = value;
        });
    }

    changeBotActivation(status) {
        store.commit('context/modifyUser', {has_bot:status})
    }

    toggleFeature(feature) {
        if(!this.id) {
            return;
        }

        let features = cloneDeep(this.features);

        if(this.features.includes(feature)){
            features = this.features.filter(existingFeature => {
                return existingFeature !== feature;
            });

        } else {
            features.push(feature);
        }
        store.commit('context/modifyUser', {features})
    }

    hasRole(role) {
        return Role.role_has_access_to_role(this.role, role);
    }
}

export default User;
