import CommandBase from "@/command/CommandBase";

class VariableReplacerCommand extends CommandBase{

    name = 'Variable replacer';
    type = 'VariableReplacer';
    hasNext = false;

    configuration = {
        variable: "",
        replacement: "",
    }

}

export default VariableReplacerCommand;
