<template>
    <div>
        <h1>Variables</h1>
        <VariableListComponent />
    </div>
</template>

<script>
import VariableListComponent from "@/components/viewSpecific/VariableView/VariableListComponent";
export default {
    name: "VariablesView",
    components: {VariableListComponent}
}
</script>

<style scoped>

</style>
