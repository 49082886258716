import CommandBase from "@/command/CommandBase";

class TextReplaceCommand extends CommandBase{

    name = 'Text Replace';
    type = 'TextReplace';
    hasNext = false;

    configuration = {
        value: "",
        search: "",
        replacement: "",
    }

}

export default TextReplaceCommand;
